<template>
  <base-section id="portfolio-tab">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/background_2.jpg')"
      height="1000"
    >
      <v-container class="fill-height px-4 py-0">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          width="100%"
        >
        <base-section-heading
          title="Portfolio"
        />
          <v-row>
            <v-col
              cols="3"
              class="text-center"
            >
              <template
                v-for="(card, i) in cards"
              >
                <base-avatar-card-portfolio
                  :key="i"
                  align="center"
                  v-bind="card"
                  @click.native="fnChangeTab(card)"
                />
                <v-divider
                  v-if="i + 1 !== cards.length"
                  :key="`divider-${i}`"
                  class="my-8"
                />
              </template>
            </v-col>

            <v-col
              cols="1"
              class="text-center px-0 mx-0"
            >
              <v-responsive
                height="calc(100% - 16px)"
                class="mx-0 px-0"
              >
                <v-divider
                  vertical
                  dark
                  class="mx-0 px-0"
                />
              </v-responsive>
            </v-col>

            <v-col
              cols="8"
            >
              <base-subheading
                size="text-h5"
                :title="cards[currentTab].title"
                space="0"
                mobileSize="text-h6"
                class="text-uppercase"
              />

              <base-title
                class="primary--text"
                :title="cards[currentTab].location"
                tag="div"
              />
              <base-body>
                {{cards[currentTab].description}}
              </base-body>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <base-list-item :text="cards[currentTab].duration" />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <base-list-item
                    v-if="cards[currentTab].value"
                    :text="cards[currentTab].value"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-responsive>
      </v-container>
    </v-img>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPortfolioTab',
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    data: () => ({
      currentTab: 0,
      cards: [
        {
          no: 0,
          title: 'Acquisition and Refurbishment of Nitrile Glove Production Line Machines',
          description: 'Acquisition and refurbishment of nitrile glove production lines and related machinery.',
          location: 'Perak',
          duration: 'November 2019',
          value: 'Project value: RM 6,400,000',
          flags: [
            require('@/assets/malaysia.png'),
          ],
          chosen: true,
        },
        {
          no: 1,
          title: 'Distribute and Export of Examination Glove',
          description: 'Distribute and export of examination gloves and medical grade gloves to the United States of America and United Kingdom.',
          location: 'USA & UK',
          duration: 'January 2020 - On going',
          flags: [
            require('@/assets/usa&uk.png'),
          ],
          chosen: false,
        },
        {
          no: 2,
          title: 'Turnkey Partner for Nitrile Examination Glove Manufacturing Plant',
          description: 'Design, procure, engineering, installation and commissioning of 16 double former production lines with its utility support system for nitrile examination glove manufacturing plant. This project is estimated to create over 1600 job opportunities in the northern region of Malaysia.',
          location: 'Kedah',
          duration: 'August 2020 - On going',
          value: 'Project value: RM 160,000,000 ',
          flags: [
            require('@/assets/malaysia.png'),
          ],
          chosen: false,
        },
        {
          no: 3,
          title: 'Nitrile Butadiene Latex (NBL) and Nitrile Examination Glove Manufacturing Plant',
          description: 'Participate in the NBL manufacturing plant project together with the utility machine design, engineering, installation and commissioning (in collaboration with Northern Corridor Investment Authority (NCIA) and Kedah State Government) located in the Kedah Rubber City. This project is expected to create over 4,000 job opportunities in the northern region of Malaysia.',
          location: 'KRC Kedah',
          duration: 'Launched in April 2022',
          value: 'Project value: RM 240,000,000',
          flags: [
            require('@/assets/malaysia.png'),
          ],
          chosen: false,
        },
      ],
    }),
    methods: {
      fnChangeTab (card) {
        this.currentTab = card.no
        this.cards[0].chosen = false
        this.cards[1].chosen = false
        this.cards[2].chosen = false
        this.cards[3].chosen = false
        card.chosen = true
      },
    },
  }
</script>
